



// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import CardData from "../../Cards/CardData";
// import { useParams } from "react-router";

// const CategoryPage = () => {
//   const { categoryName } = useParams(); // Get category from URL params
//   const [products, setProducts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchProductsByCategory = async () => {
//       try {
//         // Fetch products based on the category name from the backend
//         const res = await axios.get(
//           `http://localhost:5000/api/products?category=${categoryName}`
//         );
//         console.log("category", res.data);
        

//         if (Array.isArray(res.data)) {
//           // Deduplicate products by _id
//           const uniqueProducts = res.data.filter(
//             (value, index, self) =>
//               index === self.findIndex((t) => t._id === value._id)
//           );

//           setProducts(uniqueProducts);
//         } else {
//           setProducts([]); // Handle unexpected data format
//         }
//       } catch (error) {
//         console.error("Error fetching products by category:", error);
//         setError("Failed to fetch products.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchProductsByCategory();
//   }, [categoryName]); // Re-fetch when categoryName changes

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>{error}</p>;

//   return (
//     <div className="category-page">
//       <h1>{categoryName}</h1>
//       <div className="cards">
//         {products.length > 0 ? (
//           products.map((product) => (
//             <CardData key={product._id} product={product} />
//           ))
//         ) : (
//           <p>No products found in this category.</p>
//         )}
//       </div>
//     </div>
//   );
// };

// export default CategoryPage;


// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import CardData from "../../Cards/CardData";
// import { useParams } from "react-router";

// const CategoryPage = () => {
//   const { categoryName } = useParams(); // Get category from URL params
//   const [products, setProducts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchProductsByCategory = async () => {
//       try {
//         const res = await axios.get(
//           `http://localhost:5000/api/products?category=${categoryName}`
//         );
//         console.log("Fetched products:", res.data);
//         setProducts(res.data); // Only set products of the selected category
//       } catch (error) {
//         console.error("Error fetching products:", error);
//         setError("Failed to fetch products.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchProductsByCategory();
//   }, [categoryName]);

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>{error}</p>;

//   return (
//     <div className="category-page">
//       <h1>{categoryName}</h1>
//       <CardData products={products} /> {/* Pass products as a prop */}
//     </div>
//   );
// };

// export default CategoryPage;


import React, { useEffect, useState } from "react";
import { addToCart, removeFromCart } from "../../Cart/cartActions";
import axios from "axios";
import { useDispatch } from "react-redux";

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const dispatch = useDispatch();

  // Fetch the cart data and enrich items with product details
  const fetchCart = async () => {
    try {
      const res = await axios.get("http://localhost:5000/api/cart/");
      console.log("Fetched Cart:", res.data);

      const cartItems = res.data.items[0]?.items || [];
      const enrichedItems = await Promise.all(
        cartItems.map(async (item) => {
          try {
            const productRes = await axios.get(
              `http://localhost:5000/api/products/${item.productId}`
            );
            console.log("Product Details:", productRes.data);

            return { ...item, productDetails: productRes.data };
          } catch (error) {
            console.error("Error fetching product details:", error);
            return { ...item, productDetails: null }; // Fallback for missing details
          }
        })
      );

      setCartItems(enrichedItems);
      calculateTotalPrice(enrichedItems);
    } catch (error) {
      console.error("Error fetching cart:", error);
    }
  };

  // Calculate the total price of items in the cart
  const calculateTotalPrice = (items) => {
    const total = items.reduce(
      (sum, item) =>
        item.productDetails?.price
          ? sum + item.quantity * item.productDetails.price
          : sum,
      0
    );
    setTotalPrice(total);
  };

  // Add product to cart and fetch its details dynamically
  const handleAddToCart = async (productId, size) => {
    try {
      // Dispatch add action to Redux (optional based on your architecture)
      dispatch(addToCart(productId, size));

      // Fetch product details
      const productRes = await axios.get(
        `http://localhost:5000/api/products/${productId}`
      );

      // Add the new product to the cart state
      const newCartItem = {
        productId,
        quantity: 1,
        size,
        productDetails: productRes.data,
      };
      setCartItems((prevItems) => [...prevItems, newCartItem]);

      // Recalculate the total price
      calculateTotalPrice([...cartItems, newCartItem]);
    } catch (error) {
      console.error("Error adding product to cart:", error);
    }
  };

  const handleRemoveFromCart = async (productId) => {
    dispatch(removeFromCart(productId));
    fetchCart();
  };

  // Fetch cart data on component load
  useEffect(() => {
    fetchCart();
  }, []);

  return (
    <div>
      <h1>Your Cart</h1>
      {cartItems.length > 0 ? (
        cartItems.map((item, index) => (
          <div key={item._id || index} className="cart-item">
            <p>
              {item.productDetails?.name || "Unnamed Product"} - {item.quantity}
            </p>
            <p>Price: Rs {item.productDetails?.price || "N/A"}</p>
            <button
              onClick={() =>
                handleAddToCart(item.productId, {
                  size: item.size,
                  price: item.price,
                })
              }
            >
              +
            </button>
            <button onClick={() => handleRemoveFromCart(item.productId)}>
              -
            </button>
          </div>
        ))
      ) : (
        <p>Your cart is empty.</p>
      )}
      <h2>Total Price: Rs {totalPrice}</h2>
    </div>
  );
};

export default Cart;




