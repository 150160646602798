const initialState = {
  items: [],
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
    case "REMOVE_FROM_CART":
      return {
        ...state,
        items: action.payload, // Update cart items dynamically
      };
    default:
      return state;
  }
};



export default cartReducer;
