import React, { useState, useEffect } from "react";
import "./navbar.css";
import Login from "./login/Login";
import logo from "../../assets/THE FRUITPORIUM LOGO.png";
import "boxicons/css/boxicons.min.css";
import { Button, Dropdown, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import Cart from "./Cart/Cart";
import Signup from "./signup/Signup";
import InputGroup from "react-bootstrap/InputGroup";
import LocationDropdown from "./Location/LocationDropwdown";


const NavBar = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalSignShow, setModalSignShow] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredB2b, setIsHoveredB2b] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [animationClass, setAnimationClass] = useState("fade-in");
  const [locationMessage, setLocationMessage] = useState('');
  const [isHoveredLocation, setIsHoveredLocation] = useState(false);

  const handleOpenOffcanvas = () => setShowOffcanvas(true);
  const handleCloseOffcanvas = () => setShowOffcanvas(false);

  const successDropdown = {
    borderRadius: "5px",
    backgroundColor: isHoveredLocation ? "#709F41" : "#f2f2f2",
    color: isHoveredLocation ? "white" : "black",
    fontSize: "10px",
    height: "4vh",
    width: "5vw",
    border: "none",
  };



  const B2BDropdown = {
    borderRadius: "0px 5px 5px 0px",
    backgroundColor: isHoveredB2b ? "#709F41" : "white",
    color: isHoveredB2b ? "white" : "black",
    fontSize: "10px",
    marginTop: "17px",
    height: "4vh",
    fontWeight: "500",
  };

  const B2CDropdown = {
    borderRadius: "5px 0px 0px 5px",
    backgroundColor: isHovered ? "#709F41" : "white",
    color: isHovered ? "white" : "black",
    // marginLeft: "15px",
    fontSize: "10px",
    marginTop: "17px",
    height: "4vh",
    fontWeight: "500",
  };

  const LocationFirstbtn = {
    display: "flex",
    justifyContent: "center",
    // margin: "10px 10px",
    padding: "10px 10px",
    backgroundColor: "#709F41",
    color: "white",
    borderRadius: "30px",
    width: "7vw",
  };

  const handleFocus = () => {
    setAnimationClass("fade-out");
    setIsFocused(true);
  };

  const handleBlur = () => {
    setAnimationClass("fade-in");
    setIsFocused(false);
  };

  const handleCategoryClick = () => {
    document.getElementById("search-input").focus();
  };



  const handleLocationChange = (message) => {
    setLocationMessage(message);
  };

  const placeholders = [
    "Search for apples...",
    "Search for oranges...",
    "Search for bananas...",
    "Search for mangoes...",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimationClass("fade-out");
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % placeholders.length);
        setAnimationClass("fade-in");
      }, 500); // Duration of fade out
    }, 3000); // Change placeholder every 3 seconds

    return () => clearInterval(interval);
  }, [placeholders.length]);

  return (
    <>
      <div className="sticky">
        <nav>
          <div className="logo-text">
            <Link to="/">
              <img src={logo} alt="no icon" className="logo" />
            </Link>
          </div>
          <i
            className="bx bx-menu hamburger-icon"
            onClick={handleOpenOffcanvas}
          ></i>
          <Offcanvas show={showOffcanvas} onHide={handleCloseOffcanvas}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body></Offcanvas.Body>
          </Offcanvas>

          <div className="delivery">
  <Dropdown>
    <Dropdown.Toggle
      id="dropdown-basic"
      style={successDropdown}
      onMouseEnter={() => setIsHoveredLocation(true)}
      onMouseLeave={() => setIsHoveredLocation(false)}
    >
      New Delhi
    </Dropdown.Toggle>

    <Dropdown.Menu>
      <h1 className="location-head">Change the Location</h1>
      <div className="location-dropdown">
        <LocationDropdown onLocationChange={handleLocationChange} />
      </div>
    </Dropdown.Menu>
  </Dropdown>

  {/* Display the location message */}
  {/* {locationMessage && <p className="location-message">{locationMessage}</p>} */}
</div>


          <div className="search-bar-container">
            <InputGroup className="custom-search-bar">
              <Link smooth="true" to="#listing" className="category-dropdown">
                Categories
              </Link>

              <input
                id="search-input"
                placeholder={placeholders[currentIndex]}
                aria-label="Search"
                aria-describedby="basic-addon2"
                className={`search-input search-bar ${animationClass}`}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              <button className="search-button">
                <i className="bx bx-search"></i>
              </button>
            </InputGroup>
          </div>
          <div className="number">
            <p>For Support?</p>
            <h5>+919911719993</h5>
          </div>
          <div className="buttn">
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                variant="light"
                style={B2CDropdown}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                B2C
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setModalSignShow(true)}>
                  Sign up
                </Dropdown.Item>
                <Signup
                  show={modalSignShow}
                  onHide={() => setModalSignShow(false)}
                />
                {!isVerified ? (
                  <Dropdown.Item onClick={() => setModalShow(true)}>
                    Login
                  </Dropdown.Item>
                ) : (
                  <Dropdown>
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      My Account
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">Orders</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">My Cart</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}

                <Login show={modalShow} onHide={() => setModalShow(false)} />
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle
                id="dropdown-basic"
                variant="light"
                style={B2BDropdown}
                onMouseEnter={() => setIsHoveredB2b(true)}
                onMouseLeave={() => setIsHoveredB2b(false)}
              >
                B2B
              </Dropdown.Toggle>
            </Dropdown>
          </div>
          {!isVerified ? (
            <button className="user-login" onClick={() => setModalShow(true)}>
              <p>Login</p>
            </button>
          ) : (
            <button className="user-login">
              <p>My Account</p>
            </button>
          )}
          <Link className="cart-link" to="/cartpage">
              <Cart />
          </Link>
        </nav>
      </div>
      <Login
        show={modalShow}
        onHide={() => setModalShow(false)}
        setIsVerified={setIsVerified}
      />
    </>
  );
};

export default NavBar;
