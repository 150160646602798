// import React, { useState, useEffect } from "react";
// import SwiperSlider from "./components/coursel/SwipeSlider";
// import "./App.css";
// import NavBar from "./components/navbar/Navbar";
// import Listing from "./components/Listings/Listing";
// import CardData from "./components/Cards/CardData";
// import Footer from "./components/Footer/Footer";
// import Fruits from "./components/fruits/Fruits";
// import "boxicons/css/boxicons.min.css";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   useLocation,
// } from "react-router-dom";
// import Description from "./components/Cards/desciption page/Description";
// import ProductInfo from "./components/Footer/Product Info/ProductInfo";
// import Terms from "./components/Footer/Terms and Conditions/Terms";
// import logovideo from "../src/assets/animationnew.mp4";
// import AdminPanel from "./components/Admin/AdminPanel";

// function App() {
//   // State to control video visibility
//   const [videoEnded, setVideoEnded] = useState(false);

//   // Function to scroll to top on route change
//   const ScrollToTop = () => {
//     const { pathname } = useLocation();

//     useEffect(() => {
//       window.scrollTo(0, 0);
//     }, [pathname]);

//     return null;
//   };

//   // Automatically hide video after a few seconds or when it ends
//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setVideoEnded(true);
//     }, 4000); // Fallback in case video fails to end

//     return () => clearTimeout(timer); // Cleanup timer
//   }, []);

//   const handleVideoEnd = () => {
//     setVideoEnded(true); // Video has finished, hide it
//   };

//   return (
//     <>
//       <div className="main-class">
//         Video container
//         <div
//           id="video-container"
//           className={`video-overlay ${videoEnded ? "video-fade" : ""}`}
//         >
//           <video id="intro-video" autoPlay muted onEnded={handleVideoEnd}>
//             <source src={logovideo} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>
//         </div>

//         {/* Main content of your website */}
//         <Router>
//         <Routes>
//         <Route path="/admin" element={<AdminPanel />} />
//       </Routes>
//           <ScrollToTop />
//           <div className={videoEnded ? "content-visible" : "content-hidden"}>
//             <NavBar />
//             <div className="sticky-nav"></div>
//             <Routes>
//               <Route
//                 path="/"
//                 element={
//                   <>
//                     <SwiperSlider />
//                   </>
//                 }
//               />
//             </Routes>
//             <Listing />
//             <div>
//               <Routes>
//                 <Route
//                   path="/terms"
//                   element={
//                     <>
//                       <Terms />
//                     </>
//                   }
//                 />
//                 <Route
//                   path="/productinfo"
//                   element={
//                     <>
//                       <ProductInfo />
//                     </>
//                   }
//                 />
//               </Routes>
//             </div>
//             <div>
//               <Routes>
//                 <Route
//                   path="/"
//                   element={
//                     <>
//                       <CardData />
//                     </>
//                   }
//                 />
//                 <Route path="/fruits" element={<Fruits />} />
//               </Routes>
//               <Routes>
//                 <Route path="/description" element={<Description />} />
//               </Routes>
//             </div>
//           </div>
//           <Footer />
//         </Router>
//       </div>
//     </>
//   );
// }

// export default App;

import React, { useState, useEffect } from "react";
import SwiperSlider from "./components/coursel/SwipeSlider";
import "./App.css";
import NavBar from "./components/navbar/Navbar";
import Listing from "./components/Listings/Listing";
import CardData from "./components/Cards/CardData";
import Footer from "./components/Footer/Footer";
import "boxicons/css/boxicons.min.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Link,
} from "react-router-dom";
import Description from "./components/Cards/desciption page/Description";
import ProductInfo from "./components/Footer/Product Info/ProductInfo";
import Terms from "./components/Footer/Terms and Conditions/Terms";
import logovideo from "../src/assets/RENDER.mp4";
import AdminPanel from "./components/Admin/AdminPanel";
import CategoryPage from "./components/Listings/CategoryPage/CategoryPage";
import Banner from "./components/coursel/Banner/Banner";
import CartPage from "./components/Cart/CartPage";

function App() {
  const [videoEnded, setVideoEnded] = useState(false);
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setVideoEnded(true);
    }, 8000);

    return () => clearTimeout(timer);
  }, []);

  const handleVideoEnd = () => {
    setVideoEnded(true); // Video has finished, hide it
  };

  // Conditionally render components based on the current path
  const MainContent = () => {
    const location = useLocation(); // useLocation must be inside Router

    return (
      <>
        {location.pathname !== "/admin" && (
          <>
            <NavBar />
            <div className="sticky-nav"></div>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <SwiperSlider />
                    <Banner />
                    <Listing />
                    <CardData />
                  </>
                }
              />
              <Route
                path="/category/:categoryName"
                element={<CategoryPage />}
              />
              <Route path="/description/:id" element={<Description />} />
              <Route path="/cartpage" element={<CartPage />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/productinfo" element={<ProductInfo />} />
            </Routes>
            <Footer />
          </>
        )}
        {/* Admin panel route */}
        <Routes>
          <Route path="/admin" element={<AdminPanel />} />
        </Routes>
      </>
    );
  };

  return (
    <>
      <div className="main-class">
{/*         
         <div
          id="video-container"
          className={`video-overlay ${videoEnded ? "video-fade" : ""}`}
        >
          <video id="intro-video" autoPlay muted onEnded={handleVideoEnd}>
            <source src={logovideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>  */}

        <Router>
          <ScrollToTop />
          <div className={videoEnded ? "content-visible" : "content-hidden"}>
            <MainContent />
          </div>
        </Router>
      </div>
    </>
  );
}

export default App;

// "proxy": "http://localhost:5000",
