// import React, { useState } from "react";
// import Modal from "react-bootstrap/Modal";
// import axios from "axios";
// import "./signup.css";
// const apiUrl = "http://localhost:5000/api";

// const Signup = (props) => {
//   const [action, setAction] = useState("Sign Up");
//   const [formData, setFormData] = useState({
//     uCredentials: {
//       uEmail: "",
//     },
//     uProfile: {
//       uName: "",
//       uPhone: "",
//     },
//     uAddress: {
//       uZipCode: "",
//       uHouseNumber: "",
//       uArea: "",
//       uCity: "",
//     },
//   });
//   const [error, setError] = useState("");
//   const [success, setSuccess] = useState("");
//   // Update handleChange to handle nested objects
//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     if (name.includes("uCredentials")) {
//       setFormData({
//         ...formData,
//         uCredentials: {
//           ...formData.uCredentials,
//           [name.split(".")[1]]: value,
//         },
//       });
//     } else if (name.includes("uProfile")) {
//       setFormData({
//         ...formData,
//         uProfile: {
//           ...formData.uProfile,
//           [name.split(".")[1]]: value,
//         },
//       });
//     } else if (name.includes("uAddress")) {
//       setFormData({
//         ...formData,
//         uAddress: {
//           ...formData.uAddress,
//           [name.split(".")[1]]: value,
//         },
//       });
//     }
//   };

//   const handleSubmit = async () => {
//     try {
//       if (!formData.uCredentials.uEmail) {
//         throw new Error("Email is required");
//       }

//       const { uCredentials, uProfile, uAddress } = formData;
//       const formattedData = {
//         uCredentials: { uEmail: uCredentials.uEmail },
//         uProfile: { uName: uProfile.uName, uPhone: uProfile.uPhone },
//         uAddress: {
//           uZipCode: uAddress.uZipCode,
//           uHouseNumber: uAddress.uHouseNumber,
//           uArea: uAddress.uArea,
//           uCity: uAddress.uCity,
//         },
//       };

//       console.log("Form data being sent:", formattedData);

//       const response = await axios.post(`${apiUrl}/users/create`, formattedData);

//       console.log("Response received:", response);
//       setSuccess(response.data.message);
//       setError("");
//       setFormData({
//         uCredentials: { uEmail: "" },
//         uProfile: { uName: "", uPhone: "" },
//         uAddress: { uZipCode: "", uHouseNumber: "", uArea: "", uCity: "" },
//       });
//     } catch (err) {
//       console.error("Error:", err.message);
//       setError(err.response?.data?.error || err.message);
//       setSuccess("");
//     }
//   };

//   const modalMain = {
//     display: "flex",
//     flexDirection: "column",
//     padding: "20px",
//     height: "300px",
//     width:"100vw",
//     backGroundColor: "red",
//   };

//   return (
//     <>
//       <Modal
//         className="blurr-backdrop"
//         style={{
//           width: "100%",
//           height: "100%",
//         }}
//         {...props}
//         aria-labelledby="contained-modal-title-vcenter"
//         centered
//       >
//         <Modal.Header style={modalMain}>
//           <div className="container">
//             {/* <div className="header">
//               <div className="text">{action}</div>
//               <div className="underline"></div>
//             </div> */}
//             <div className="inputs">

//             </div>
//             {/* <div className="submit-container">
//               <div
//                 onClick={handleSubmit}
//               >
//                 Sign Up
//               </div>
//             </div> */}
//             {error && <p className="error">{error}</p>}
//             {success && <p className="success">{success}</p>}
//           </div>
//         </Modal.Header>
//       </Modal>
//     </>
//   );
// };

// export default Signup;

// Login.js
// import React, { useState } from "react";
// import logo from "../../../assets/THE FRUITPORIUM LOGO.png";
// import Modal from "react-bootstrap/Modal";
// import Form from "react-bootstrap/Form";
// import InputGroup from "react-bootstrap/InputGroup";
// import { initializeApp } from "firebase/app";
// import {
//   getAuth,
//   RecaptchaVerifier,
//   signInWithPhoneNumber,
// } from "firebase/auth";
// import axios from "axios";
// import "./signup.css";
// const apiUrl = "http://localhost:5000/api";

// const Signup = ({ setIsVerified, ...props }) => {
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [otp, setOtp] = useState("");
//   const [confirmationResult, setConfirmationResult] = useState(null);
//   const [error, setError] = useState("");
//   const [otpSent, setOtpSent] = useState(false);


//   const modalMain = {
//     display: "flex",
//     flexDirection: "column",
//     width:"40vw",
//     height: "50vh",
//     backgroundColor: "white",
//     marginLeft:"-20%",
//   };

//   return (
//     <Modal
//       className="blurr-backdrop"
//       // style={{
//       //   width: "100%",
//       //   height: "100%",
//       //   maxWidth: "100%",
//       //   maxHeight: "100%",
//       //   boxSizing: "border-box",
//       // }}
//       {...props}
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header style={modalMain}>
       
//       </Modal.Header>
//     </Modal>
//   );
// };

// export default Signup;


import React, { useState } from "react";
import logo from "../../../assets/THE FRUITPORIUM LOGO.png";
import Modal from "react-bootstrap/Modal";
import { FaInstagramSquare } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import "./signup.css";

const Signup = ({ setIsVerified, ...props }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    zipcode: "",
    houseno: "",
    area: "",
    city: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted: ", formData);
  };

  return (
    <Modal
      className="signup-modal"
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="signup-modal-header">
        <div className="form-container">
          <div className="form-header">
            <img src={logo} alt="Logo" className="form-logo" />
            <h6>Where Nature's Sweetest Gifts Gather</h6>
            <div className="icon-div">
            <FaInstagramSquare className="social-icon" />
            <FaFacebook className="social-icon" />
            </div>
          </div>
          <form onSubmit={handleSubmit} className="form-body">
            <div className="form-group">
              <input
                className="form-input"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <label className="form-label">Name</label>
            </div>
            <div className="form-group">
              <input
                className="form-input"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <label className="form-label">Email</label>
            </div>
            <div className="form-group">
              <input
                className="form-input"
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              <label className="form-label">Phone</label>
            </div>


            <div className="form-group">
              <input
                className="form-input"
                type="text"
                name="area"
                value={formData.area}
                onChange={handleChange}
                required
              />
              <label className="form-label">Area</label>
            </div>
            <div className="form-group">
              <input
                className="form-input"
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                required
              />
              <label className="form-label">City</label>
            </div>
            <button type="submit" className="form-submit-button">
              Submit
            </button>
          </form>
        </div>
      </Modal.Header>
    </Modal>
  );
};

export default Signup;
