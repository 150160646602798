// src/components/redux/reducers/index.js
import { combineReducers } from 'redux';
import cartReducer from './cartReducer';  // Importing cartReducer

// Combine your reducers into a single rootReducer
const rootReducer = combineReducers({
  cart: cartReducer,  // This will handle the 'cart' piece of state
});

export default rootReducer;
