import axios from "axios";

export const addToCart = (productId, selectedSize) => async (dispatch) => {
  console.log("Adding to cart:", productId); // Add this debug log
  try {
    const response = await axios.post(
      "http://localhost:5000/api/cart/addToCart",
      {
        productId: productId._id, // Ensure `productId._id` exists
        size: selectedSize.size,
        price: selectedSize.price,
      }
    );

    console.log("addTocart", response.data);
    
    dispatch({ type: "ADD_TO_CART", payload: response.data.items });
  } catch (error) {
    console.error("Error adding to cart:", error);
  }
};

export const removeFromCart = (productId) => async (dispatch) => {
  try {
    const response = await axios.post(
      "http://localhost:5000/api/cart/removeFromCart",
      { productId }
    );
    dispatch({ type: "REMOVE_FROM_CART", payload: response.data.items });
  } catch (error) {
    console.error("Error removing from cart:", error);
  }
};
