// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import axios from "axios";
// import image from "../../../assets/pineapple.JPG";
// import banana from "../../../assets/banana.JPG";
// import papaya from "../../../assets/papaya.JPG";
// import "./description.css";

// const Description = () => {
//   const [currentImage, setCurrentImage] = useState(image);
//   const { id } = useParams(); // Fetch product ID from URL
//   const [product, setProduct] = useState(null); // Store fetched product details
//   const [selectedSlab, setSelectedSlab] = useState(null);

//   // Fetch product details on mount and when the id changes
//   useEffect(() => {
//     const fetchProductDetails = async () => {
//       try {
//         const res = await axios.get(`http://localhost:5000/api/products/${id}`);
//         setProduct(res.data);
//       } catch (error) {
//         console.error("Error fetching product details:", error);
//       }
//     };
//     fetchProductDetails();
//   }, [id]);

//   // Set default slab when product is loaded
//   useEffect(() => {
//     if (product && product.by_size && product.by_size.length > 0) {
//       setSelectedSlab(product.by_size[0]);
//     }
//   }, [product]);

//   // Magnifier effect logic
//   useEffect(() => {
//     const magnifier = document.getElementById("magnifier");
//     const imgContainer = document.querySelector(".img-container");
//     const img = document.querySelector(".product-img");

//     if (!imgContainer || !magnifier || !img) return;

//     const moveMagnifier = (e) => {
//       const { left, top, width, height } = img.getBoundingClientRect();
//       const magnifierSize = magnifier.offsetWidth / 2;

//       let x = e.pageX - left - window.pageXOffset;
//       let y = e.pageY - top - window.pageYOffset;

//       // Prevent magnifier from going out of bounds
//       x = Math.max(magnifierSize, Math.min(x, width - magnifierSize));
//       y = Math.max(magnifierSize, Math.min(y, height - magnifierSize));

//       magnifier.style.left = `${x - magnifierSize}px`;
//       magnifier.style.top = `${y - magnifierSize}px`;
//       magnifier.style.backgroundPosition = `-${(x * 2) - magnifierSize}px -${
//         (y * 2) - magnifierSize
//       }px`;
//     };

//     const showMagnifier = () => {
//       magnifier.style.display = "block";
//       magnifier.style.backgroundImage = `url(${img.src})`;
//       magnifier.style.backgroundSize = `${img.width * 2}px ${img.height * 2}px`; // Zoom factor
//     };

//     const hideMagnifier = () => {
//       magnifier.style.display = "none";
//     };

//     imgContainer.addEventListener("mousemove", moveMagnifier);
//     imgContainer.addEventListener("mouseenter", showMagnifier);
//     imgContainer.addEventListener("mouseleave", hideMagnifier);

//     return () => {
//       imgContainer.removeEventListener("mousemove", moveMagnifier);
//       imgContainer.removeEventListener("mouseenter", showMagnifier);
//       imgContainer.removeEventListener("mouseleave", hideMagnifier);
//     };
//   }, [currentImage]);

//   // Handle case where product or by_size is null/undefined
//   if (!product) return <p>Loading...</p>;

//   const images = [image, banana, papaya];

//   return (
//     <>
//       <div className="desc-main">
//         <div className="img-sec">
//           <div className="img-container">
//             <img src={currentImage} alt="Product" className="product-img" />
//             <div className="magnifier-glass" id="magnifier"></div>
//           </div>
//           <div className="img-select">
//             {images.map((img, index) => (
//               <div className="img-item" key={index}>
//                 <img
//                   src={img}
//                   alt={`Thumbnail ${index + 1}`}
//                   onClick={() => setCurrentImage(img)}
//                 />
//               </div>
//             ))}
//           </div>
//         </div>
//         <div className="info-sec">
//           <p className="name">{product.Name || "Product Name"}</p>
//           {selectedSlab && (
//             <>
//               <p className="mrp">MRP: {selectedSlab.price}</p>
//               <p className="price">Discounted Price: {selectedSlab.pDiscount}</p>
//             </>
//           )}
//           <h3 className="head-size">Pack Sizes</h3>
//           <div className="packs">
//             {product.by_size.map((pack, index) => (
//               <button
//                 className={`size-1 slab ${
//                   selectedSlab && selectedSlab.size === pack.size ? "active" : ""
//                 }`}
//                 key={index}
//                 onClick={() => setSelectedSlab(pack)}
//               >
//                 <p>{pack.size}</p>
//                 <p>{pack.price}</p>
//               </button>
//             ))}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Description;





import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import image from "../../../assets/pineapple.JPG";
import banana from "../../../assets/banana.JPG";
import papaya from "../../../assets/papaya.JPG";
import quality from "../../../assets/guarantee-certificate.png";
import "./description.css";

const Description = () => {
  const [currentImage, setCurrentImage] = useState(image);
  const [isVisible, setIsVisible] = useState(false);
  const [selectedSlab, setSelectedSlab] = useState({
    size: "1kg",
    price: "Rs 100",
    pDiscount: "Rs 90",
  });

  const product = {
    Name: "Static Product Name",
    Description: "This is a static description of the product.",
    Type: "Fruit",
    Origin: "India",
    "Nutrient Value & Health Benefits": "Rich in vitamins and minerals.",
    Ingredients: "Fresh fruit only.",
    Unit: "1kg",
    FssaiLicense: "1234567890",
    "Shelf Life": "5 days",
    CustomerCareDetails: "Call 1800-123-4567 for support.",
    ReturnPolicy: "Returns accepted within 2 days.",
    "Storage and Uses": "Keep refrigerated, consume within 2 days.",
    by_size: [
      { size: "500g", price: "Rs 50", pDiscount: "Rs 45" },
      { size: "1kg", price: "Rs 100", pDiscount: "Rs 90" },
      { size: "2kg", price: "Rs 180", pDiscount: "Rs 160" },
    ],
  };

  const images = [image, banana, papaya, image];

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  // Magnifier effect is kept as in the original code
  useEffect(() => {
    const magnifier = document.getElementById("magnifier");
    const imgContainer = document.querySelector(".img-container");
    const img = document.querySelector(".product-img");

    function moveMagnifier(e) {
      const pos = getCursorPos(e);
      let x = pos.x;
      let y = pos.y;
      const magnifierWidth = magnifier.offsetWidth / 2;
      const magnifierHeight = magnifier.offsetHeight / 2;

      if (x > img.width - magnifierWidth) x = img.width - magnifierWidth;
      if (x < magnifierWidth) x = magnifierWidth;
      if (y > img.height - magnifierHeight) y = img.height - magnifierHeight;
      if (y < magnifierHeight) y = magnifierHeight;

      magnifier.style.left = `${x - magnifierWidth}px`;
      magnifier.style.top = `${y - magnifierHeight}px`;
      magnifier.style.backgroundPosition = `-${x * 2 - magnifierWidth}px -${
        y * 2 - magnifierHeight
      }px`;
    }

    function getCursorPos(e) {
      const rect = img.getBoundingClientRect();
      const x = e.pageX - rect.left;
      const y = e.pageY - rect.top;
      return { x: x - window.pageXOffset, y: y - window.pageYOffset };
    }

    if (imgContainer) {
      imgContainer.addEventListener("mousemove", moveMagnifier);
      imgContainer.addEventListener("mouseenter", () => {
        magnifier.style.display = "block";
        magnifier.style.backgroundImage = `url(${img.src})`;
        magnifier.style.backgroundSize = `${img.width * 2}px ${img.height * 2}px`;
      });
      imgContainer.addEventListener("mouseleave", () => {
        magnifier.style.display = "none";
      });
    }

    return () => {
      if (imgContainer) {
        imgContainer.removeEventListener("mousemove", moveMagnifier);
        imgContainer.removeEventListener("mouseenter", () => {});
        imgContainer.removeEventListener("mouseleave", () => {});
      }
    };
  }, [currentImage]);

  return (
    <>
      <div className="desc-main">
        <div className="img-sec">
          <div className="img-container">
            <img src={currentImage} alt="Product" className="product-img" />
            <div className="magnifier-glass" id="magnifier"></div>
          </div>
          <div className="img-select">
            {images.map((img, index) => (
              <div className="img-item" key={index}>
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setCurrentImage(img);
                  }}
                >
                  <img src={img} alt={`Thumbnail ${index + 1}`} />
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="info-sec">
          <p className="name">{product.Name}</p>
          <p className="mrp">MRP: {selectedSlab.price}</p>
          <p className="price">Discounted Price: {selectedSlab.pDiscount}</p>
          <p className="tax">(Inclusive of all taxes)</p>
          <div className="desc-btn">
            <button className="add-btn">ADD</button>
            <button className="save-btn">Save for Later</button>
          </div>
          <h3 className="head-size">Pack Sizes</h3>
          <div className="packs">
            {product.by_size.map((pack, index) => (
              <button
                className={`size-1 slab ${
                  selectedSlab && selectedSlab.size === pack.size ? "active" : ""
                }`}
                key={index}
                onClick={() => setSelectedSlab(pack)}
              >
                <p>{pack.size}</p>
                <p>{pack.price}</p>
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="desc-section">
        <hr />
        <h3 className="second-head">Why choose Fruitporium?</h3>
        <div className="boxes">
          {[...Array(4)].map((_, index) => (
            <div className="box" key={index}>
              <img src={quality} alt="Quality Guarantee" />
              <h6>Quality</h6>
              <p>You can trust</p>
            </div>
          ))}
        </div>
        <div className="desc-data">
          <div>
            <h5>About the product</h5>
            <p>{product.Description}</p>
          </div>
          <hr className="desc-hr" />
          <div>
            <h5>Other Product Info</h5>
            <button onClick={toggleVisibility}>
              {isVisible ? "-" : "+"}
            </button>
            {isVisible && (
              <div>
                <h6>Name:</h6>
                <p>{product.Name}</p>
                <h6>Type:</h6>
                <p>{product.Type}</p>
                <h6>Country of Origin:</h6>
                <p>{product.Origin}</p>
                <h6>Benefits:</h6>
                <p>{product["Nutrient Value & Health Benefits"]}</p>
                <h6>Ingredients:</h6>
                <p>{product.Ingredients}</p>
                <h6>Unit:</h6>
                <p>{product.Unit}</p>
                <h6>FSSAI License:</h6>
                <p>{product.FssaiLicense}</p>
                <h6>Shelf Life:</h6>
                <p>{product["Shelf Life"]}</p>
                <h6>Customer Care Details:</h6>
                <p>{product.CustomerCareDetails}</p>
                <h6>Return Policy:</h6>
                <p>{product.ReturnPolicy}</p>
                <h6>Storage and Uses:</h6>
                <p>{product["Storage and Uses"]}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Description;

