// import React, { useState } from "react";
// import "./cart.css"

// const CartPage = () => {
//   // Sample cart items
//   const [cartItems, setCartItems] = useState([]);

//   // Update quantity
//   const handleQuantityChange = (id, change) => {
//     setCartItems((prevItems) =>
//       prevItems.map((item) =>
//         item.id === id ? { ...item, quantity: item.quantity + change } : item
//       )
//     );
//   };

//   // Remove item from cart
//   const handleRemove = (id) => {
//     setCartItems((prevItems) => prevItems.filter((item) => item.id !== id));
//   };

//   // Calculate total price
//   const getTotalPrice = () => {
//     return cartItems.reduce(
//       (total, item) => total + item.price * item.quantity,
//       0
//     );
//   };

//   return (
//     <div style={styles.cartContainer}  className="cart-main">

//       <h2>Shopping Cart</h2>

//       {cartItems.length === 0 ? (
//         <p>Your cart is empty.</p>
//       ) : (
//         <div>
//           {cartItems.map((item) => (
//             <div key={item.id} style={styles.cartItem}>
//               <span>{item.name}</span>
//               <span>${item.price}</span>
//               <div style={styles.quantityControl}>
//                 <button
//                   onClick={() => handleQuantityChange(item.id, -1)}
//                   disabled={item.quantity === 1}
//                 >
//                   -
//                 </button>
//                 <span>{item.quantity}</span>
//                 <button onClick={() => handleQuantityChange(item.id, 1)}>
//                   +
//                 </button>
//               </div>
//               <span>${item.price * item.quantity}</span>
//               <button
//                 onClick={() => handleRemove(item.id)}
//                 style={styles.removeButton}
//               >
//                 Remove
//               </button>
//             </div>
//           ))}

//           <div style={styles.totalPrice}>
//             <h3>Total Price: ${getTotalPrice()}</h3>
//             <button style={styles.checkoutButton}>Proceed to Checkout</button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// const styles = {
//   cartContainer: {
//     padding: "20px",
//     maxWidth: "600px",
//     margin: "70px auto",
//   },
//   cartItem: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     borderBottom: "1px solid #ccc",
//     padding: "10px 0",
//   },
//   quantityControl: {
//     display: "flex",
//     alignItems: "center",
//     gap: "10px",
//   },
//   removeButton: {
//     backgroundColor: "#ff6347",
//     border: "none",
//     padding: "5px 10px",
//     color: "white",
//     cursor: "pointer",
//   },
//   totalPrice: {
//     marginTop: "20px",
//     textAlign: "right",
//   },
//   checkoutButton: {
//     backgroundColor: "#28a745",
//     color: "white",
//     border: "none",
//     padding: "10px 20px",
//     cursor: "pointer",
//     marginTop: "10px",
//   },
// };

// export default CartPage;

// import React, { useState } from "react";
// import "./cart.css";

// const CartPage = () => {
//   const [cartItems, setCartItems] = useState([]);

//   const handleQuantityChange = (id, change) => {
//     setCartItems((prevItems) =>
//       prevItems.map((item) =>
//         item.id === id ? { ...item, quantity: item.quantity + change } : item
//       )
//     );
//   };

//   const handleRemove = (id) => {
//     setCartItems((prevItems) => prevItems.filter((item) => item.id !== id));
//   };

//   const getTotalPrice = () => {
//     return cartItems.reduce(
//       (total, item) => total + item.price * item.quantity,
//       0
//     );
//   };

//   return (
//     <div className="cart-main cart-container">
//       <h2>Shopping Cart</h2>

//       {cartItems.length === 0 ? (
//         <p>Your cart is empty.</p>
//       ) : (
//         <div>
//           {cartItems.map((item) => (
//             <div key={item.id} className="cart-item">
//               <span>{item.name}</span>
//               <span>${item.price}</span>
//               <div className="quantity-control">
//                 <button
//                   onClick={() => handleQuantityChange(item.id, -1)}
//                   disabled={item.quantity === 1}
//                 >
//                   -
//                 </button>
//                 <span>{item.quantity}</span>
//                 <button onClick={() => handleQuantityChange(item.id, 1)}>
//                   +
//                 </button>
//               </div>
//               <span>${item.price * item.quantity}</span>
//               <button
//                 onClick={() => handleRemove(item.id)}
//                 className="remove-button"
//               >
//                 Remove
//               </button>
//             </div>
//           ))}

//           <div className="total-price">
//             <h3>Total Price: ${getTotalPrice()}</h3>
//             <button className="checkout-button">Proceed to Checkout</button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default CartPage;

// import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import { addToCart, removeFromCart } from "../Cart/cartActions";
// import axios from "axios";

// const Cart = () => {
//   const [cartItems, setCartItems] = useState([]);
//   const [totalPrice, setTotalPrice] = useState(0);
//   const dispatch = useDispatch();

//   const fetchCart = async () => {
//     try {
//       const res = await axios.get("http://localhost:5000/api/cart"); // Fetch the cart (global cart)
//       console.log("fetch cart", res.data);

//       setCartItems(res.data.items);
//       calculateTotalPrice(res.data.items);
//     } catch (error) {
//       console.error("Error fetching cart:", error);
//     }
//   };

//   const handleAddToCart = async (productId, size) => {
//     dispatch(addToCart(productId, size));
//     fetchCart();
//   };

//   const handleRemoveFromCart = async (productId) => {
//     dispatch(removeFromCart(productId));
//     fetchCart();
//   };

//   const calculateTotalPrice = (items) => {
//     const total = items.reduce(
//       (sum, item) => sum + item.quantity * item.productId.price,
//       0
//     );
//     setTotalPrice(total);
//   };

//   useEffect(() => {
//     fetchCart();
//   }, []);

//   return (
//     <div>
//       <br />
//       <br />
//       <br />
//       <br />
//       <br />
//       <br />
//       <br />
//       <br />
//       <h1>Your Cart</h1>
//       {cartItems.map((item) => (
//   <div key={item.productId._id} className="cart-item">
//     <p>
//       {item.productId?.Name ? item.productId.Name : "Unnamed Product"} - {item.quantity}
//     </p>
//     <p>Price: Rs {item.productId?.price || "N/A"}</p>
//     <button
//       onClick={() =>
//         handleAddToCart(item.productId._id, {
//           size: item.size,
//           price: item.price,
//         })
//       }
//     >
//       +
//     </button>
//     <button onClick={() => handleRemoveFromCart(item.productId._id)}>
//       -
//     </button>
//   </div>
// ))}

//       <h2>Total Price: Rs {totalPrice}</h2>
//     </div>
//   );
// };

// export default Cart;

import React, { useEffect, useState } from "react";
import { addToCart, removeFromCart } from "../Cart/cartActions";
import axios from "axios";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import "./cart.css";
import anaar from "../../assets/anaar.JPG";
import { useDispatch } from "react-redux";

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const dispatch = useDispatch();

  // Fetch the cart data and enrich items with product details
  const fetchCart = async () => {
    try {
      const res = await axios.get("http://localhost:5000/api/cart/cartpage");
      console.log("Fetched Cart:", res.data);

      // Enrich cart items with product details
      const enrichedItems = await Promise.all(
        res.data.items.map(async (item) => {
          try {
            const productRes = await axios.get(
              `http://localhost:5000/api/products/${item.productId._id}` // Extract _id
            );
            console.log("Product Response:", productRes.data);

            return { ...item, productDetails: productRes.data };
          } catch (error) {
            console.error("Error fetching product details:", error);
            return { ...item, productDetails: null }; // Fallback for missing details
          }
        })
      );

      setCartItems(enrichedItems);
      calculateTotalPrice(enrichedItems);
    } catch (error) {
      console.error("Error fetching cart:", error);
    }
  };

  // Calculate the total price of items in the cart
  const calculateTotalPrice = (items) => {
    const total = items.reduce(
      (sum, item) =>
        item.productDetails?.price
          ? sum + item.quantity * item.productDetails.price
          : sum,
      0
    );
    setTotalPrice(total);
  };

  const handleAddToCart = async (productId, size) => {
    dispatch(addToCart(productId, size));
    fetchCart();
  };

  const handleRemoveFromCart = async (productId) => {
    dispatch(removeFromCart(productId));
    fetchCart();
  };

  // Fetch cart data on component load
  useEffect(() => {
    fetchCart();
  }, []);

  return (
    //   <div className="cart-page">
    //   <h1>Your Basket</h1>
    //   <div className="cart-items">
    //     {cartItems.length > 0 ? (
    //       cartItems.map((item, index) => {
    //         const sizeDetails = item.productDetails?.by_size.find(
    //           (sizeObj) => sizeObj.size === item.size
    //         );
    //         return (
    //           <div key={item._id || index} className="cart-item-card">
    //             <div className="cart-item-info">
    //               <img
    //                 className="cart-item-image"
    //                 src="https://via.placeholder.com/100"
    //                 alt={item.productDetails?.Name || "Product Image"}
    //               />
    //               <div className="cart-item-text">
    //                 <h4>{item.productDetails?.Name || "Unnamed Product"}</h4>
    //                 <p>Size: {item.size || "N/A"}</p>
    //                 <p>
    //                   Price: Rs {sizeDetails?.price || "N/A"} (
    //                   {sizeDetails?.pDiscount
    //                     ? `Discounted: Rs ${sizeDetails.pDiscount}`
    //                     : "No Discount"})
    //                 </p>
    //                 <p>Quantity: {item.quantity}</p>
    //               </div>
    //             </div>
    //             <div className="cart-item-actions">
    //               <button
    //                 className="action-btn add-btn"
    //                 onClick={() =>
    //                   handleAddToCart(item.productId, { size: item.size })
    //                 }
    //               >
    //                 +
    //               </button>
    //               <button
    //                 className="action-btn remove-btn"
    //                 onClick={() => handleRemoveFromCart(item.productId)}
    //               >
    //                 -
    //               </button>
    //             </div>
    //           </div>
    //         );
    //       })
    //     ) : (
    //       <p>Your cart is empty.</p>
    //     )}
    //   </div>
    //   {cartItems.length > 0 && (
    //     <div className="cart-total">
    //       <h2>Total: Rs {totalPrice}</h2>
    //       <button className="checkout-btn">Proceed to Checkout</button>
    //     </div>
    //   )}
    // </div>
    <div className="cart-page">
      <h1>Your Basket</h1>
      <div className="cart-items">
        <div key="1" className="cart-item-card">
          <div className="cartcard-info">
            <div className="cart-item-image">
              <img src={anaar} alt="image" />
            </div>
            <div>
              <h3>Washington Apple</h3>
              <div className="home-delivery">
                <TiTick className="tick" />
                <p>Home Delivery</p>
              </div>
              <hr style={{ width: "100%" }} />
              <div className="cart-weightage">
                <p>Qty:</p>
                <div className="cart-item-actions">
                  <button className="action-btn">-</button>
                  <p>1</p>
                  <button className="action-btn">+</button>
                </div>
                <p>Rs 355/-</p>
              </div>
            </div>
          </div>
          <ImCross className="cross" />
        </div>
        <div className="checkout">
          <div>
            <div className="subtotal">
              <h6>Subtotal:</h6>
              <p>rs 4566/-</p>
            </div>
            <div className="checkout-delivery">
              <h6>Delivery:</h6>
              <p>rs 4566/-</p>
            </div>
            <hr />
            <div className="checkout-total">
              <h4>Total:</h4>
              <p>rs 4566/-</p>
            </div>
            <hr />
            <button>Checkout</button>
          </div>
          <p>
            This site is protected by reCaptcha and the Google Privacy Policy
            and Terms of Service apply.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Cart;

// useEffect(() => {
//     const fetchCart = async () => {
//       const response = await axios.get('/api/cart/' + userId);
//       setCart(response.data);
//     };

//     fetchCart();
//   }, [userId]);
