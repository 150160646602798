import React, { useState } from 'react';
import axios from 'axios';

const apiUrl = "http://localhost:5000/api";

const AdminPanel = () => {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const [registerData, setRegisterData] = useState({ username: '', password: '' });
  const [error, setError] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState('');
  const [isRegistering, setIsRegistering] = useState(false); // Toggle between login and register

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRegisterChange = (e) => {
    setRegisterData({ ...registerData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isRegistering) {
      // Handle registration
      try {
        await axios.post(`${apiUrl}/auth/admin/register`, registerData);
        alert('Registration successful! You can now log in.');
        setIsRegistering(false); // Switch back to login
      } catch (err) {
        setError('Registration failed: Admin already registered');
      }
    } else {
      // Handle login
      try {
        const res = await axios.post(`${apiUrl}/auth/admin/login`, formData);
        setToken(res.data.token);
        setIsLoggedIn(true);
      } catch (err) {
        setError('Invalid credentials');
      }
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setToken('');
  };

  if (isLoggedIn) {
    return (
      <div>
        <h1>Admin Dashboard</h1>
        <button onClick={handleLogout}>Logout</button>
        <div>
          <p>Welcome to the admin panel! Here, you can manage your website content.</p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h2>{isRegistering ? 'Admin Registration' : 'Admin Login'}</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        {isRegistering ? (
          <>
            <input
              type="text"
              name="username"
              placeholder="Username"
              onChange={handleRegisterChange}
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              onChange={handleRegisterChange}
              required
            />
            <button type="submit">Register</button>
          </>
        ) : (
          <>
            <input
              type="text"
              name="username"
              placeholder="Username"
              onChange={handleChange}
              required
            />
            <input
              type="password"
              name="password"
              placeholder="Password"
              onChange={handleChange}
              required
            />
            <button type="submit">Login</button>
          </>
        )}
        <button type="button" onClick={() => setIsRegistering(!isRegistering)}>
          {isRegistering ? 'Switch to Login' : 'Switch to Register'}
        </button>
      </form>
    </div>
  );
};

export default AdminPanel;
