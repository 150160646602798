

import React from 'react';
import { Provider } from 'react-redux'; // Import Provider
import ReactDOM from 'react-dom/client'; // Use react-dom/client
import App from './App';
import store from '../src/components/redux/Store'; // Import your store
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'; // or your stylesheet

// Create the root element
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the application
root.render(
  <Provider store={store}> 
    <App />
  </Provider>
);






